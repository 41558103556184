import * as Swal from "sweetalert2";

window.Main = new (function () {

    var that = this;

    /**
     * Init
     */
    this.init = function () {

        $('.in-gallery img').on('click', that.openGallery);

        $('#formConfirm button').on('click', that.validateForm);

        $('.HomeBannerBox').slick({
            dots: true,
            arrows: false,
            slidesToShow: 1,
            infinite: false,
            autoplay: true
        });

        $('.rating-carrousel').slick({
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 99999,
            },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    }
                }]
        });

        $('.HomeBannerBox .item .play-video').on('click', that.showVideo);

    };

    /**
     * Open video
     */
    this.showVideo = function () {
        let videoSource = $(this).data('video');
        let videoHTML = "<div class='banner-wrap-video'><video width='100%' height='auto' autoplay muted playsinline loop='true'> \n" +
            "                        <source src='" + videoSource + "' type='video/mp4'>\n" +
            "                        Your browser does not support the video tag.\n" +
            "                    </video></div>";

        if (!$(this).hasClass('no-video')) {
            Swal.fire({
                html: videoHTML,
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false
            });
        }

    };

    /**
     * Validate form
     */
    this.validateForm = function () {
        let theChoose = ($(this).hasClass('ok')) ? 'asistirá' : 'no asistirá';
        var validationPass = true;
        $('#confirm').val(theChoose);
        $("form :input").removeClass('error');

        $("form :input").each(function () {
            var input = $(this); // This is the jquery object of the input, do what you will
            var required = $(this).attr('required');

            if (typeof required !== 'undefined' && required !== false && $.trim($(this).val()) == '') {
                $(input).addClass('error');
                validationPass = false;
            }
        });

        if (validationPass) {
            $('#formConfirm').submit();
        }
    };

});
